import React, {useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import {performAjaxRequest} from "../../helpers";
import BsIcon from "../../components/bsIcon";
import {useNavigate} from "react-router-dom";
import SubscriptionModal from "../../components/modals/subscriptionModal";
import Autocomplete from "../../components/autocomplete";
import AccordionWrapper from "../../components/accordionWrapper";
import ApplicationModal from "../../components/modals/applicationModal";
import ApplicationsTable from "../../components/tables/applicationsTable";
import GroupMembersTable from "../../components/tables/groupMembersTable";

function AdminApplicationsPage(props) {
    const { id } = useParams();
    const { t } = useTranslation();
    const applicationModalRef = useRef();
    const subscriptionModalRef = useRef();
    const navigate = useNavigate();

    const [event, setEvent] = useState(null);
    const [applications, setApplications] = useState(null);
    const [application, setApplication] = useState(null);
    const [newApplicationUser, setNewApplicationUser] = useState(null);

    const fetchApplications = () => {
        performAjaxRequest(
            'GET',
            `/admin/events/${id}/applications.json`,
            null,
            {success:(response) => {setApplications(response);}}
        )
    };

    useEffect(() => {
        performAjaxRequest(
            'GET',
            `/admin/events/${id}.json`,
            null,
            {success:(response) => {setEvent(response);}}
        )
        fetchApplications();
    }, []);

    const unappliedUsers = useMemo(() => {
        return event?.group.users?.filter((user) => !applications?.map(application => application.user.id).includes(user.id));
    }, [event, applications])

    const openApplicationModal = (id) => {
        setNewApplicationUser(id)
        applicationModalRef?.current?.showModal()
    }

    const autocompleteSelect = function (event, ui) {
        const autocompleteId = ui.item.id

        if (applications?.map(a => a.user.id).includes(autocompleteId)) { // already applied
            const existingApplication = applications.find(a => a.user.id === autocompleteId);
            if (existingApplication) {
                setApplication(existingApplication)
                subscriptionModalRef?.current?.showModal()
            }
        } else {
            openApplicationModal(ui.item)
        }
    }

    const modalButton = application => (
        <Button variant="white" type="submit" data-bs-target="#subscriptionModal" data-bs-toggle="modal"
                onClick={() => {setApplication(application)}} >
            { t('admin.modify') }
        </Button>
    )

    return <div>
        {event?.closed ? <BsIcon role="button" icon="arrow-left-circle" classNames="fs-1  text-primary" onClick={() => navigate(-1)}/> : <>
            <SubscriptionModal ref={subscriptionModalRef}
                               plans={event?.group.plans}
                               event_id={event?.id}
                               group_id={event?.group.id}
                               application={application}
                               handleSubscriptionBuyFailed={() => {
                                   alert(t('admin.subscription_creation_failed'))
                               }}
                               handleSubscriptionBought={fetchApplications}/>
            <ApplicationModal ref={applicationModalRef}
                              user={newApplicationUser}
                              group={event?.group}
                              fetchApplications={fetchApplications}
                              {...props} />

            <div className='d-flex justify-content-between'>
                <BsIcon role="button" icon="arrow-left-circle" classNames="fs-1  text-primary" onClick={() => navigate(-1)}/>
                <AccordionWrapper button={<Button variant="primary" type="submit"><BsIcon classNames="me-2" icon="person-fill" />{t('admin.new_applications')}</Button>}>
                    <Autocomplete url={`/admin/users.json`}
                                  selectCallback={autocompleteSelect} />
                </AccordionWrapper>
            </div>
        </>}

        <div className="d-flex flex-column mb-3">
            <h4>{event?.name}</h4>
            <span className="text-secondary">{event?.datetime}</span>
        </div>

        <ApplicationsTable applications={applications} actionColumn={modalButton} fetchApplications={fetchApplications} readOnly={event?.closed}/>

        {!event?.closed && <>
            <div className="d-flex justify-content-between my-4">
                <a className="btn btn-white" data-method="put" data-confirm={t('admin.confirm_default')}
                   href={`/admin/events/${id}/cancel`}>
                    <BsIcon classNames="me-1" icon='x-circle'/>
                    {t('admin.cancel_event')}
                </a>

                <a className={`btn btn-white ${event?.closable ? '' : 'disabled'}`} data-method="put" data-confirm={t('admin.confirm_default')}
                   href={`/admin/events/${id}/close`}>
                    <BsIcon classNames="me-1" icon='check-all'/>
                    {t('admin.close_event')}
                </a>
            </div>

            {unappliedUsers?.length > 0 && <GroupMembersTable users={unappliedUsers} groupName={event?.group.name} action={openApplicationModal}/>}
        </>}
    </div>
}

export default AdminApplicationsPage;
