import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import AccordionWrapper from "../accordionWrapper";
import TextSmall from "../textSmall";

function GroupMembersTable({users, groupName, action}) {
    const { t } = useTranslation();

    return <div className="mt-5">
        <AccordionWrapper arrow={true} classNames="p-0" button={<h4>{t('admin.more_dancer')}</h4>}>
        <TextSmall text={t('admin.more_dancer_info', {group: groupName})} />

        <div className="table-responsive mt-4">
            <table className="table">
                <tbody>
                {users?.map((user) => {
                    return <tr key={user.id} className="border-bottom">
                        <td className="ps-0 text-start">
                            <span className="fs-6">{user.full_name}</span>
                            <br className="d-sm-none"/>
                            <span className="d-sm-none small text-secondary">{user.email}</span>
                        </td>
                        <td className="ps-0 d-none d-sm-table-cell">
                            <span>{user.email}</span>
                        </td>
                        <td className="pe-0 text-end">
                            <Button variant="white" type="submit" onClick={() => action(user)}>
                                {t('admin.add')}
                            </Button>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    </AccordionWrapper>
    </div>
}

export default GroupMembersTable;
